import React from "react";


export const TracingModal = ({ show, onClose, info }) => {
  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Trazabilidad</h4>
          </div>

          <div className="modal-body">
            {info.filter(o => !!o.UKEY).length > 0 ? (
              <>
                <p>Pedidos con este lote:</p>
                <ul>
                  {info.filter(o => !!o.UKEY).map((o) => (
                    <li><a target="_blank" href={`/pedido/${o.UKEY}`}>{o.PUBLIC_ID}</a></li>
                  ))}
                </ul>
              </>
            ) : (
              <p>Este lote no se ha usado en ningún pedido.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}