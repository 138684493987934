import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";

import { environment } from "../../deploy/environment";
import { ORDER_TYPE, URGENCY_STATUS_LITERALS, USER_ROLES } from "../../core/constants/roles";
import { formatDate } from "../../core/utils/date";
import { removeObjectWithId } from "../../core/utils/array";
import { getBaseColumn } from "../orders/utils/columns/columns.utils";
import {
  getOrders,
  getPickups,
  getPromotions,
  getStatus,
  setPickup,
  setPickupStatus
} from "../../resources/services";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import PickupStatusBadge from "../../components/pickup-status-badge/pickup-status-badge";
import { SpinnerLoading } from "../../components/loading/loading";
import UrgencyStatusBadge from "../../components/urgency-status-badge/urgency-status-badge";
import { EditPickupModal } from "../recogidas/modals/editPickupModal";
import { NewPickupModal } from "../recogidas/modals/newPickupModal";

import "./landing.css";


class Landing extends Component {
  constructor (props) {
    super(props);

    this.statusNames = {}

    this.state = {
      ordersSummaryData: [],
      pickupsData: [],
      urgentOrdersData: [],
      loadingOrders: false,
      loadingPickups: false,

      showCreatePickupModal: false,
      showEditPickupModal: false,
      pickupData: null,

      promotionsData: []
    }

    this.ordersSummaryColumns = [
      {
        ...getBaseColumn('status', 'Estado', 'status')
      },
      {
        ...getBaseColumn('count', 'Recuento', 'count')
      },
      {
        name: 'Lista',
        cell: (row) => {
          return [
            <Link to={`/pedidos?status=${row.statusId}&order_type=${ORDER_TYPE.NEW}`} className="btn btn-primary">
              <i className="entypo-eye"></i>
            </Link>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ]

    this.arreglosSummaryColumns = [
      {
        ...getBaseColumn('status', 'Estado', 'status')
      },
      {
        ...getBaseColumn('count', 'Recuento', 'count')
      },
      {
        name: 'Lista',
        cell: (row) => {
          return [
            <Link to={`/pedidos?status=${row.statusId}&order_type=${ORDER_TYPE.FIX}`} className="btn btn-primary">
              <i className="entypo-eye"></i>
            </Link>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ]

    this.pickupsColumns = [
      {
        id: 'request_date',
        name: 'Fecha solicitud',
        selector: 'CREATED_AT',
        sortable: true,
        cell: (row) => {
          return formatDate(row.CREATED_AT)
        },
      },
      {
        name: 'Fecha recogida',
        selector: 'DATE',
        sortable: true,
        cell: (row) => {
          return formatDate(row.DATE)
        },
      },
      {
        name: 'Estado',
        selector: 'STATUS_ID',
        cell: (row) => {
          return <PickupStatusBadge pickupStatusId={row.STATUS_ID} pickupStatus={row.STATUS_NAME}/>
        },
        sortable: true,
      }
    ]

    if (props.app.idRol == USER_ROLES.ADMIN) {
      this.pickupsColumns.push(
        {
          name: 'Gestión',
          cell: (row) => {
            return [
              <button
                type="button"
                className="btn btn-red"
                onClick={() => this.editPickup(row)}
              ><i className="entypo-pencil"></i>
              </button>
            ]
          },
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        }
      )
    }

    this.urgentOrdersColumns = [
      {
        id: 'request_date',
        name: 'Fecha solicitud',
        selector: 'URGENCY_REQUEST_DATE',
        sortable: true,
        cell: (row) => {
          return formatDate(row.URGENCY_REQUEST_DATE)
        },
      },
      {
        ...getBaseColumn('lote', 'Nº Lote', 'ID_SERIES', true)
      },
      {
        ...getBaseColumn('clinica', 'Clinica', 'CLINIC_NAME', true),
      },
      {
        ...getBaseColumn('urgency', 'Urgencia', 'URGENCY_STATUS', true),
        cell: (row) => (
          row.URGENCY_STATUS !== null
            ? <UrgencyStatusBadge urgencyStatusId={row.URGENCY_STATUS} urgencyStatus={URGENCY_STATUS_LITERALS[row.URGENCY_STATUS]}/>
            : <></>
        )
      },
      {
        name: 'Lista',
        cell: (row) => {
          return [
            <Link to={`/pedidos?open_order=${row.UKEY}`} target="_blank" className="btn btn-primary">
              <i className="entypo-eye"></i>
            </Link>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ]

    if (this.props.app.idRol == USER_ROLES.CLIENT) {
      this.urgentOrdersColumns = removeObjectWithId(this.urgentOrdersColumns, 'clinica')
    }
  }

  componentDidMount() {
    getStatus()
      .then((result) => {
        if (result.response !== 0 && result.status !== 0) {
          result.status
            .filter((v) => v.ID !== '6')
            .forEach((v) => (this.statusNames[v.ID] = v.NAME))
          this.loadData()
        } else {
          toast('Error al obtener los datos', { type: toast.TYPE.ERROR })
        }
      })
    this.loadPromotions()
    toast("¡FAQ'S y ACADEMY ya disponible!" , { type: toast.TYPE.INFO })
  }

  loadData () {
    this.setState({ loadingOrders: true, loadingPickups: true })
    getOrders()
      .then((result) => {
        if (result.response) {
          const ordersSummaryData = Object.keys(this.statusNames).map((status) => (
            {
              status: this.statusNames[status],
              statusId: status,
              count: result.orders.filter(o => o.ORDER_TYPE == ORDER_TYPE.NEW).filter(o => o.STATUS == status).length
            }
          ))

          const arreglosSummaryData = Object.keys(this.statusNames).map((status) => (
            {
              status: this.statusNames[status],
              statusId: status,
              count: result.orders.filter(o => o.ORDER_TYPE == ORDER_TYPE.FIX).filter(o => o.STATUS == status).length
            }
          ))

          const urgentOrdersData = result.orders.filter(o => o.URGENCY_STATUS !== null && o.URGENCY_STATUS >= 0)

          this.setState({ ordersSummaryData, arreglosSummaryData, urgentOrdersData })

        } else {
          toast('Error al obtener los datos', { type: toast.TYPE.ERROR })
        }
      })
      .finally(() => {
        this.setState({ loadingOrders: false })
      })

    getPickups()
      .then((result) => {
        if (result.response){
          this.setState({
            pickupsData: result.pickups
          })
        } else {
          toast("Se ha producido un error al realizar la consulta", { type: toast.TYPE.ERROR })
        }
      })
      .finally(() => {
        this.setState({ loadingPickups: false })
      })
  }

  loadPromotions = () => {
    getPromotions(true)
      .then((result) => {
        if(result?.response){
          this.setState({
            promotionsData: result.promotions
          })
        }else{
          toast("Se ha producido un error al realizar la consulta", {type: toast.TYPE.ERROR})
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  editPickup = (pickupData) => {
    this.setState({
      pickupData,
      showEditPickupModal: true
    })
  }

  handleOnCloseEditPickupModal = () => {
    this.setState({
      pickupData: null,
      showEditPickupModal: false,
    })
  }

  handleOnCloseCreatePickupModal = () => {
    this.setState({ showCreatePickupModal: false })
  }

  handlePickupEdition = (pickupId, newStatus, newComment, answer) => {
    this.setState({ loadingPickups: true })
    setPickupStatus(pickupId, newStatus, answer)
      .then((result) => {
        this.loadData()
        if (result) {
          if (result.updated == 1) {
            toast('La recogida se ha actualizado con éxito', {
              type: toast.TYPE.SUCCESS
            })
          } else {
            toast('Se ha producido un error', {
              type: toast.TYPE.ERROR
            })
          }
        }
      })
      .finally(() => {
        this.setState({ showEditPickupModal: false })
      })
  }

  handleNewPickupCreation = (date, description, order) => {
    this.setState({ loadingPickups: true })
    setPickup(date, description, order)
      .then((result) => {
        this.loadData()
        if (result) {
          if (result.created == 1) {
            toast('La recogida se ha creado con éxito', {
              type: toast.TYPE.SUCCESS
            })
          } else {
            toast('Se ha producido un error', {
              type: toast.TYPE.ERROR
            })
          }
        }
      })
      .finally(() => {
        this.setState({ showCreatePickupModal: false })
      })
  }

  render () {
    return (
      <Fragment>
        <Breadcrumb section={[]} />
        {this.state.promotionsData.length ? (
          <div className="row promotions-container">
            {this.state.promotionsData.map(promotion =>
              <div className="col-xs-12 col-lg-6">
                <img className="promotion-banner" src={environment.urlPromotionsResources + promotion.IMAGE}/>
              </div>
            )}
          </div>
        ) : null}
        <div className="row summary-container">
          <div className="col-xs-12 col-lg-6">
            <DataTable
              title={"Pedidos"}
              columns={this.ordersSummaryColumns}
              data={this.state.ordersSummaryData}
              noDataComponent={"No hay datos a mostrar"}
              progressPending={this.state.loadingOrders}
              progressComponent={<SpinnerLoading />}
              actions={(
                <div className="text-center">
                  <Link to="/pedido/" className="btn btn-primary">
                    <i className="entypo-plus"/> Crear pedido
                  </Link>
                </div>
              )}
            />
          </div>
          <div className="col-xs-12 col-lg-6">
            <DataTable
              title={"Arreglos"}
              columns={this.arreglosSummaryColumns}
              data={this.state.arreglosSummaryData}
              noDataComponent={"No hay datos a mostrar"}
              progressPending={this.state.loadingOrders}
              progressComponent={<SpinnerLoading />}
              actions={(
                <div className="text-center">
                  <Link to="/pedido/arreglo/" className="btn btn-primary">
                    <i className="entypo-plus"/> Crear arreglo
                  </Link>
                </div>
              )}
            />
          </div>
          <div className="col-xs-12 col-lg-6">
            <DataTable
              title={"Recogidas"}
              columns={this.pickupsColumns}
              data={this.state.pickupsData}
              noDataComponent={"No hay datos a mostrar"}
              progressPending={this.state.loadingPickups}
              progressComponent={<SpinnerLoading />}
              pagination={true}
              defaultSortField={'CREATED_AT'}
              actions={(
                <div className="text-center">
                  <button className="btn btn-primary" onClick={() => { this.setState({ showCreatePickupModal: true })}}>
                    <i className="entypo-plus"></i> Solicitar recogida
                  </button>
                </div>
              )}
            />
          </div>
          <div className="col-xs-12 col-lg-6">
            <DataTable
              title={"Pedidos urgentes"}
              columns={this.urgentOrdersColumns}
              data={this.state.urgentOrdersData}
              noDataComponent={"No hay datos a mostrar"}
              progressPending={this.state.loadingOrders}
              progressComponent={<SpinnerLoading />}
              pagination={true}
              defaultSortField={'URGENCY_REQUEST_DATE'}
            />
          </div>
        </div>
        {this.state.showEditPickupModal && (
          <EditPickupModal
            show={this.state.showEditPickupModal}
            onClose={this.handleOnCloseEditPickupModal}
            onCreate={this.handlePickupEdition}
            pickupData={this.state.pickupData}
          />
        )}
        {this.state.showCreatePickupModal && (
          <NewPickupModal
            show={this.state.showCreatePickupModal}
            onClose={this.handleOnCloseCreatePickupModal}
            onCreate={this.handleNewPickupCreation}
          />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(Landing)