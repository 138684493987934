import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import Breadcrumb from '../../components/breadcrumb/breadcrumb'
import Table from '../../components/table/table'
import {
  getOrders,
  getStatus,
  setNewDeliveryDate,
  requestUrgency,
  rejectUrgency,
  acceptUrgency,
} from '../../resources/services'
import { AdminModal } from './modals/adminModal'
import { ClientModal } from './modals/clientModal'
import { getAllColumns } from './utils/columns/columns.utils'
import { withQueryParamsHandler } from "../../hook/useQueryParams.hook";
import { USER_ROLES } from "../../core/constants/roles";

import './orders.css'

class Orders extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      rowSelected: {},
      showModal: false,
      loadingTable: false,
      redirect: false,
      urlRedirect: '',
      showFavoriteModal: false,
      showClientModal: false
    }

    this.statusOptions = [{ label: 'undefined', value: 0 }]

    const params = this.props.queryParamsHandler.searchObj
    this.statusFilter = params?.status
    this.orderTypeFilter = params?.order_type
    this.urgentFilter = params?.urgent
    this.openOrderDetail = params?.open_order

    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleOnCloseAdminModal = this.handleOnCloseAdminModal.bind(this)
    this.handleOnCloseFavoriteModal = this.handleOnCloseFavoriteModal.bind(this)
    this.handleOnSaveFavoriteModal = this.handleOnSaveFavoriteModal.bind(this)
    this.handleOnCloseClientModal = this.handleOnCloseClientModal.bind(this)
    this.setNewDeliveryDate = this.setNewDeliveryDate.bind(this)
    this.handleOnSaveClientModalModal =
      this.handleOnSaveClientModalModal.bind(this)

    this.handleUrgencyRequest = this.handleUrgencyRequest.bind(this)
    this.handleUrgencyAcceptance = this.handleUrgencyAcceptance.bind(this)
    this.handleUrgencyRejection = this.handleUrgencyRejection.bind(this)

    this.loadOrders = this.loadOrders.bind(this)
    this.interval = null

    this.columns = getAllColumns(
      props.app.idRol,
      (props) => this.setState({ ...props }),
      () => this.loadOrders(true)
    )
  }

  componentDidMount() {
    this.loadOrders().then(() => {
      if (this.openOrderDetail) {
        const order = this.state.data.filter(o => o.UKEY === this.openOrderDetail)?.pop()
        if (order) {
          if (this.props.app.idRol === USER_ROLES.CLIENT) {
            this.setState({
              showClientModal: true,
              rowSelected: order
            })
          } else if ([USER_ROLES.ADMIN, USER_ROLES.DESIGNER].includes(this.props.app.idRol)) {
            this.setState({
              showModal: true,
              rowSelected: order
            })
          }
        }
      }
    })
    if (this.props.app.idRol === '99' || this.props.app.idRol === '4') {
      this.interval = setInterval(() => {
        this.loadOrders(true)
      }, 5000)
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  loadOrders(onlyOrders = false) {
    return getOrders().then((result) => {
      if (result.response) {
        let orders = result.orders
        if (this.statusFilter) orders = orders.filter((o) => o.STATUS == this.statusFilter)
        if (this.orderTypeFilter) orders = orders.filter((o) => o.ORDER_TYPE == this.orderTypeFilter)
        if (this.urgentFilter) orders = orders.filter((o) => o.URGENCY_STATUS !== null)

        this.setState(
          {
            data: orders
          },
          () => {
            if (!onlyOrders) {
              getStatus().then((result) => {
                if (result.response !== 0 && result.status !== 0) {
                  this.statusOptions = result.status
                    .filter((v) => v.ID !== '6')
                    .map((v) => ({ label: v.NAME, value: v.ID }))
                  this.setState({ loadingTable: false })
                } else {
                  toast('No se ha podido obtener los estados del pedidio', {
                    type: toast.TYPE.ERROR
                  })
                }
              })
            }
          }
        )
      } else {
        toast('error al obtener los pedidios', { type: toast.TYPE.ERROR })
      }
    })
  }

  handleSelectChange = () => {
    this.loadOrders()
    this.handleOnCloseAdminModal()
  }

  handleOnCloseClientModal = () => {
    this.setState({ showClientModal: false, rowSelected: null })
  }

  handleOnCloseFavoriteModal = () => {
    this.setState({ showFavoriteModal: false, rowSelected: null })
  }

  handleOnCloseAdminModal = () => {
    this.setState({ showModal: false, rowSelected: null })
  }

  handleOnSaveFavoriteModal = (favoriteText) => {
    this.addFavoriteOrder(favoriteText)
  }

  setNewDeliveryDate = (newDate) => {
    const ukey = this.state.rowSelected['UKEY']
    return setNewDeliveryDate({ id: ukey, newDeliveryDate: newDate }).then(
      (response) => {
        if (response.response === 1 && response.update === 1) {
          toast('Se ha modificar el pedido correctamente', {
            type: toast.TYPE.SUCCESS
          })
          this.loadOrders()
        } else {
          toast('No se ha modificar el pedido correctamente', {
            type: toast.TYPE.ERROR
          })
        }
      }
    )
  }

  handleOnSaveClientModalModal = (newDate) => {
    this.setNewDeliveryDate(newDate).then(this.handleOnCloseClientModal)
  }

  handleUrgencyRequest = (urgencyRequestComment) => {
    const ukey = this.state.rowSelected['UKEY']
    return requestUrgency(ukey, urgencyRequestComment)
      .then((response) => {
        if (response.response === 1 && response.created === 1) {
          toast('Se ha solicitado tratar el pedido como urgente.', {
            type: toast.TYPE.SUCCESS
          })
          if (this.props.app.idRol === USER_ROLES.CLIENT) {
            this.setState({
              redirect: true,
              urlRedirect: '/landing'
            })
          }
        } else {
          toast('Se ha produucido un error.', {
            type: toast.TYPE.ERROR
          })
        }
      })
  }

  handleUrgencyAcceptance = () => {
    const ukey = this.state.rowSelected['UKEY']
    return acceptUrgency(ukey)
      .then((response) => {
        if (response.response === 1 && response.updated === 1) {
          toast('Se ha aceptado el pedido como urgente.', {
            type: toast.TYPE.SUCCESS
          })
        } else {
          toast('Se ha produucido un error.', {
            type: toast.TYPE.ERROR
          })
        }
      })
  }

  handleUrgencyRejection = (urgencyRejectionComment) => {
    const ukey = this.state.rowSelected['UKEY']
    return rejectUrgency(ukey, urgencyRejectionComment)
      .then((response) => {
        if (response.response === 1 && response.updated === 1) {
          toast('Se ha rechazado el pedido como urgente.', {
            type: toast.TYPE.SUCCESS
          })
        } else {
          toast('Se ha produucido un error.', {
            type: toast.TYPE.ERROR
          })
        }
      })
  }


  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.urlRedirect
          }}
        />
      )
    }

    return (
      <Fragment>
        <Breadcrumb section={[]} />
        <iframe id="my_iframe" style={{ display: 'none' }}></iframe>
        <div className="text-right row-control">
          <Link to="/pedido/" className="btn btn-primary">
            <i className="entypo-plus"></i> Crear pedido
          </Link>
          <Link to="/pedido/arreglo/" className="btn btn-white">
            <i className="entypo-plus"></i> Crear arreglo
          </Link>
        </div>
        {this.state.loadingTable ? null : (
          <Table
            keyFilter={'NOMBRE_PACIENTE'}
            data={this.state.data}
            columns={this.columns}
            title="Pedidos"
            pagination
            defaultSortField="FECHA_CREACION"
            defaultSortAsc={false}
          />
        )}
        {this.state.showModal && (
          <AdminModal
            show={this.state.showModal}
            idRol={this.props.app.idRol}
            statusOptions={this.statusOptions}
            data={this.state.rowSelected}
            onClose={this.handleOnCloseAdminModal}
            onDeleteOrder={() => {
              this.loadOrders()
              this.handleOnCloseAdminModal()
            }}
            onChangeStatus={this.handleSelectChange}
            onNewDeliveryDate={(newDate) => {
              this.loadOrders()
              this.handleOnCloseAdminModal()
            }}
            onRequestUrgency={(urgencyRequestComment) => {
              this.handleUrgencyRequest(urgencyRequestComment).finally(() => {
                this.loadOrders()
                this.handleOnCloseAdminModal()
              })
            }}
            onAcceptUrgency={() => {
              this.handleUrgencyAcceptance().finally(() => {
                this.loadOrders()
                this.handleOnCloseAdminModal()
              })
            }}
            onRejectUrgency={(urgencyRejectionComment) => {
              this.handleUrgencyRejection(urgencyRejectionComment).finally(() => {
                this.loadOrders()
                this.handleOnCloseAdminModal()
              })
            }}
          />
        )}
        {this.state.showClientModal && (
          <ClientModal
            show={this.state.showClientModal}
            idRol={this.props.app.idRol}
            onClose={this.handleOnCloseClientModal}
            onNewDeliveryDate={() => {
              this.loadOrders()
              this.handleOnCloseClientModal()
            }}
            onRequestUrgency={(urgencyRequestComment) => {
              this.handleUrgencyRequest(urgencyRequestComment).finally(() => {
                this.loadOrders()
                this.handleOnCloseClientModal()
              })
            }}
            data={this.state.rowSelected}
          ></ClientModal>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(withQueryParamsHandler(Orders))
