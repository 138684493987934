import React from 'react'
import { Link } from 'react-router-dom'
import { environment as ENV } from '../../../deploy/environment'
import { userImage } from './../../../resources/utils'

export const getCustomerColumns = (idRol, onDelete) => {
  const imageStyle = {
    height: '50px',
    width: '30px',
    padding: '12px 0px'
  }
  let columns = [
    {
      name: 'Logo',
      selector: 'LOGO',
      cell: (row) => (
        <div key={`div-logo-${row.UKEY}`} style={imageStyle}>
          <img
            style={{ borderRadius: '50px', width: '25px', height: '25px' }}
            alt="logo"
            src={
              row.LOGO && row.LOGO !== ''
                ? ENV.rootFiles +
                  '/customer-attachment/' +
                  row.UKEY +
                  '/profile/' +
                  row.LOGO
                : userImage
            }
          />
        </div>
      ),
      width: '60px'
    },
    {
      name: 'Nombre de la clínica',
      selector: 'CLINIC_NAME',
      sortable: true
    },
    {
      name: 'Persona de contacto',
      selector: 'NAME',
      sortable: true
    },
    {
      name: 'Email',
      selector: 'EMAIL',
      sortable: true
    },
    {
      name: 'Pedidos',
      selector: 'N_ORDERS',
      sortable: true,
      sortFunction: (rowA, rowB) => {
        return parseInt(rowA.N_ORDERS) - parseInt(rowB.N_ORDERS)
      },
      width: '70px'
    },
    {
      name: 'Estado',
      selector: 'STATUS',
      sortable: true,
      cell: (row) => (
        <div className={`status-${Number(row.STATUS) + 2}`}>
          {row.STATUS === '1' ? 'Activo' : 'Inactivo'}
        </div>
      )
    },
    {
      name: 'Facturar a',
      selector: 'D_NAME',
      sortable: true
    },
    {
      name: 'Descuento',
      selector: 'DISCOUNT',
      sortable: true,
      sortFunction: (rowA, rowB) => rowA.DISCOUNT - rowB.DISCOUNT,
      cell: (row) => `${row.DISCOUNT}%`
    },
    {
      cell: (row) => {
        return [
          <Link to={`/cliente/${row.UKEY}`} key={`table-link-${row.UKEY}`}>
            <button
              type="button"
              className="btn btn-default"
              style={{ marginRight: '10px' }}
            >
              <i className={`entypo-${idRol === '4' ? 'eye' : 'pencil'}`}></i>
            </button>
          </Link>,
          idRol !== '99' ? null : (
            <button
              type="button"
              className="btn btn-red"
              key={`table-button-${row.UKEY}`}
              onClick={() => onDelete && onDelete(row)}
            >
              <i className="entypo-trash"></i>
            </button>
          )
        ]
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ]

  if (idRol !== '99') {
    columns = columns.filter((v) => v.selector !== 'DISCOUNT')
  }

  return columns
}
