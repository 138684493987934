import React, { Component } from 'react';
import propTypes from 'prop-types';

import './pickup-status-badge.css';

class PickupStatusBadge extends Component {

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className={`pickup-status-badge pickup-status-badge__${this.props.pickupStatusId}`}>{this.props.pickupStatus}</div>
    )
  }
}

PickupStatusBadge.propTypes = {
  pickupStatusId: propTypes.string,
  pickupStatus: propTypes.string,
};

PickupStatusBadge.defaultProps = {
  pickupStatusId: null,
  pickupStatus: '',
};

export default PickupStatusBadge