import React, {useEffect} from 'react';
import Input from '../../../components/input/input';

export const TutorialModal = ({ show, onClose, onCreate, tutorialData }) => {

  const [titulo, setTitulo] = React.useState(null);
  const [youtubeId, setYoutubeId] = React.useState(null);
  const [comentario, setComentario] = React.useState(null);

  useEffect(() => {
    if (!tutorialData) return
    setTitulo(tutorialData.TITLE)
    setYoutubeId(tutorialData.YOUTUBE_URL)
    setComentario(tutorialData.COMMENT)
  }, [tutorialData])

  const handleOnCreate = () => {
    onCreate &&
    onCreate(tutorialData?.ID, titulo, youtubeId, comentario);
  };

  const handleOnClose = () => {
    onClose && onClose();
  }

  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">{tutorialData ? "Editar" : "Nuevo"} tutorial</h4>
          </div>
          <div className="modal-body">
            <form role="form" className="row form form-horizontal form-groups-bordered" autoComplete="off">
              <div className="form-group col-xs-12">
                <Input
                  label={"Título"}
                  type={"text"}
                  id="titulo"
                  inputClass="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setTitulo(v)}
                  placeholder={"Título del tutorial o FAQ"}
                  value={titulo}
                  noFormGroup
                  isolateComponent
                />
              </div>
              <div className="form-group col-xs-12">
                <Input
                  label={"Youtube ID"}
                  type={"text"}
                  id="youtube"
                  inputClass="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setYoutubeId(v)}
                  placeholder={"Id del vídeo de youtube (ej: P2M11Uhq7qs)"}
                  value={youtubeId}
                  noFormGroup
                  isolateComponent
                />
              </div>
              <div className="form-group col-xs-12">
                <div className={`col-sm-3 text-right col-xs-4`}>
                  <label htmlFor="comentario" className="control-label bold">Comentario</label>
                </div>
                <div className={`col-sm-9 form-group col-xs-8`}>
                  <textarea
                    id="comentario"
                    className="form-control"
                    onChange={(e) => setComentario(e.target.value)}
                    placeholder={"Opcional"}
                    value={comentario}
                    rows={5}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              onClick={() => handleOnCreate()}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}