import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import logo from "./../../assets/images/logo-login.png";

class SidebarMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { idRol, idUser } = this.props.app;
    return (
      <div className="sidebar-menu" style={{ minHeight: "1734px" }}>
        <header className="logo-env">
          <div className="sidebar-collapse" onClick={this.props.onCollapsed}>
            <a href="#" className="sidebar-collapse-icon with-animation">
              <i className="entypo-menu"></i>
            </a>
          </div>
          <div className="sidebar-mobile-menu visible-xs">
            <a href="#" className="with-animation">
              <i className="entypo-menu"></i>
            </a>
          </div>
          <div className="logo" style={{width: "100%"}}>
            <a href="#" className="logo">
              <img src={logo} alt="" width="150"/>
            </a>
          </div>
        </header>

        <ul id="main-menu" className="">
          {/* <li id="search" className="root-level">
          <form method="get" action="#">
            <input type="text" name="q" className="search-input" placeholder="Buscador..." />
            <button type="submit"><i className="entypo-search"></i></button>
          </form>
        </li> */}
          {idRol === "99" ? (
            <Fragment>
              <li className="root-level has-sub highlighted">
                <Link
                  onClick={this.props.onChangeSection("landing")}
                  to="/landing"
                  className="active"
                >
                  <i className="entypo-home"></i>
                  <span>Home</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("users")}
                  to="/usuarios"
                  className="active"
                >
                  <i className="entypo-user"></i>
                  <span>Usuarios</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("customers")}
                  to="/clientes"
                  className="active"
                >
                  <i className="entypo-users"></i>
                  <span>Clientes</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("podologos")}
                  to={`/podologos`}
                  className="active"
                >
                  <i className="entypo-user"></i>
                  <span>Podólogos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("pedidos")}
                  to="/pedidos"
                  className=""
                >
                  <i className="entypo-doc-text"></i>
                  <span>Pedidos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("En fabricación")}
                  to="/fabricacion"
                  className=""
                >
                  <i className="entypo-tools"></i>
                  <span>En fabricación</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("Control de calidad")}
                  to="/control-de-calidad"
                  className=""
                >
                  <i className="entypo-eye"></i>
                  <span>Control de calidad</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("Finalizados")}
                  to="/finalizados"
                  className=""
                >
                  <i className="entypo-check"></i>
                  <span>Finalizados</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("enviados")}
                  to="/enviados"
                  className=""
                >
                  <i className="entypo-inbox"></i>
                  <span>Enviados</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("recogidas")}
                  to="/recogidas"
                  className=""
                >
                  <i className="entypo-box"></i>
                  <span>Recogidas</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("favoritos")}
                  to="/favoritos"
                  className=""
                >
                  <i className="entypo-star"></i>
                  <span>Favoritos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("precios")}
                  to="/precios"
                  className=""
                >
                  <i className="entypo-database"></i>
                  <span>Materiales y precios</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("facturas")}
                  to="/facturas"
                  className=""
                >
                  <i className="entypo-doc-text"></i>
                  <span>Facturas</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("festivos")}
                  to="/festivos"
                  className=""
                >
                  <i className="entypo-calendar"></i>
                  <span>Festivos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("promociones")}
                  to="/promociones"
                  className=""
                >
                  <i className="entypo-ticket"></i>
                  <span>Promociones</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("tutoriales-gestion")}
                  to="/tutoriales-gestion"
                  className=""
                >
                  <i className="entypo-book"></i>
                  <span>Tutoriales</span>
                </Link>
              </li>
            </Fragment>
          ) : null}
          {idRol === "4" ? (
            <Fragment>
              <li className="root-level has-sub highlighted">
                <Link
                  onClick={this.props.onChangeSection("landing")}
                  to="/landing"
                  className="active"
                >
                  <i className="entypo-home"></i>
                  <span>Home</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("customers")}
                  to="/clientes"
                  className="active"
                >
                  <i className="entypo-users"></i>
                  <span>Clientes</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("pedidos")}
                  to="/pedidos"
                  className=""
                >
                  <i className="entypo-doc-text"></i>
                  <span>Pedidos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("enviados")}
                  to="/enviados"
                  className=""
                >
                  <i className="entypo-inbox"></i>
                  <span>Enviados</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("favoritos")}
                  to="/favoritos"
                  className=""
                >
                  <i className="entypo-star"></i>
                  <span>Favoritos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("Finalizados")}
                  to="/finalizados"
                  className=""
                >
                  <i className="entypo-check"></i>
                  <span>Finalizados</span>
                </Link>
              </li>
              <li className="root-level has-sub highlighted">
                <Link
                  onClick={this.props.onChangeSection("tutoriales")}
                  to="/tutoriales"
                  className=""
                >
                  <i className="entypo-book"></i>
                  <span>FAQs y Tutoriales</span>
                </Link>
              </li>
            </Fragment>
          ) : null}
          {idRol === "3" ? (
            <Fragment>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("Control de calidad")}
                  to="/control-de-calidad"
                  className=""
                >
                  <i className="entypo-eye"></i>
                  <span>Control de calidad</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("Finalizados")}
                  to="/finalizados"
                  className=""
                >
                  <i className="entypo-check"></i>
                  <span>Finalizados</span>
                </Link>
              </li>
              <li className="root-level has-sub highlighted">
                <Link
                  onClick={this.props.onChangeSection("tutoriales")}
                  to="/tutoriales"
                  className=""
                >
                  <i className="entypo-book"></i>
                  <span>FAQs y Tutoriales</span>
                </Link>
              </li>
            </Fragment>
          ) : null}
          {idRol === "1" ? (
            <Fragment>
              <li className="root-level has-sub highlighted">
                <Link
                  onClick={this.props.onChangeSection("landing")}
                  to="/landing"
                  className="active"
                >
                  <i className="entypo-home"></i>
                  <span>Home</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("customers")}
                  to={`/cliente/${idUser}`}
                  className="active"
                >
                  <i className="entypo-user"></i>
                  <span>Perfil</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("podologos")}
                  to={`/podologos`}
                  className="active"
                >
                  <i className="entypo-user"></i>
                  <span>Podólogos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("pedidos")}
                  to="/pedidos"
                  className=""
                >
                  <i className="entypo-doc-text"></i>
                  <span>Pedidos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("recogidas")}
                  to="/recogidas"
                  className=""
                >
                  <i className="entypo-box"></i>
                  <span>Recogidas</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("favoritos")}
                  to="/favoritos"
                  className=""
                >
                  <i className="entypo-star"></i>
                  <span>Favoritos</span>
                </Link>
              </li>
              <li className="root-level has-sub">
                <Link
                  onClick={this.props.onChangeSection("facturas")}
                  to="/facturas"
                  className=""
                >
                  <i className="entypo-doc-text"></i>
                  <span>Facturas</span>
                </Link>
              </li>
              <li className="root-level has-sub highlighted">
                <Link
                  onClick={this.props.onChangeSection("tutoriales")}
                  to="/tutoriales"
                  className=""
                >
                  <i className="entypo-book"></i>
                  <span>FAQs y Tutoriales</span>
                </Link>
              </li>
            </Fragment>
          ) : null}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
});

export default connect(mapStateToProps, null)(SidebarMenu);
