
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Table from '../../components/table/table';
import { getDaysOff, setDayOff, deleteDayOff } from '../../resources/services';
import { NewDayOffModal } from './modals/newDayOffModal';
import { withQueryParamsHandler } from '../../hook/useQueryParams.hook';
import Breadcrumb from "../../components/breadcrumb/breadcrumb";

class DaysOff extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loadError: false,
      data:[],
      showDayOffModal: false
    };

    this.handleOnCloseNewDayOffModal = this.handleOnCloseNewDayOffModal.bind(this)
    this.handleNewDayOffCreation = this.handleNewDayOffCreation.bind(this)

    this.columns = [
      {
        name: 'Fecha',
        selector: 'DATE',
        sortable: true,
      },
      {
        name: 'Descripción',
        selector: 'DESCRIPTION',
        sortable: true
      },
      {
        name: 'Acciones',
        cell: (row) => {
          return [
            <button 
              type="button"
              className="btn btn-red"
              onClick={() => this.deleteDayOff(row.ID)}
            ><i className="entypo-trash"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];
  }

  componentDidMount() {
    this.loadDaysOff()
  }

  deleteDayOff(dayOffId){
    window.confirm(`Estás seguro de que deseas eliminar el día festivo?`) && deleteDayOff(dayOffId)
      .then((result) => {
        if(result.response && result.deleted != 0){
          this.loadDaysOff()
        }
      })
  }

  loadDaysOff() {
    this.setState({ loading: true })
    getDaysOff()
      .then((result) => {
        if(result.response){
          this.setState({
            data: result.days
          })
        }else{
          toast("Se ha producido un error al realizar la consulta", {type: toast.TYPE.ERROR})
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleOnCloseNewDayOffModal = () => {
    this.setState({ showDayOffModal: false })
  }

  handleNewDayOffCreation = (date, description) => {
    this.setState({ loading: true })
    setDayOff(date, description).then((result) => {
      this.setState({ loading: false, showDayOffModal: false })
      this.loadDaysOff()
      if (result) {
        if (result.created == 1) {
          toast('El día festivo se ha creado con éxito', {
            type: toast.TYPE.SUCCESS
          })
        } else {
          toast('Se ha producido un error', {
            type: toast.TYPE.ERROR
          })
        }
      }
    })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Festivos", url: "festivos"},
          ]}
        />
        <div className="text-right row-control">
          {this.props.app.idRol == '99' && (
            <button className="btn btn-primary" onClick={() => this.setState({showDayOffModal: true})}>
              <i className="entypo-plus"></i> Añadir festivo
            </button>
          )}
        </div>
        <Table
          keyFilter={'DATE'}
          data={this.state.data}
          columns={this.columns}
          title="Días festivos"
          pagination
        />
        {this.state.showDayOffModal && (
          <NewDayOffModal
            show={this.state.showDayOffModal}
            onClose={this.handleOnCloseNewDayOffModal}
            onCreate={this.handleNewDayOffCreation}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(withQueryParamsHandler(DaysOff))
