import React, {useEffect} from "react";
import Select from '../../../components/select/select'
import Input from "../../../components/input/input";

export const EditPickupModal = ({ show, onClose, onCreate, pickupData }) => {
  const [pickupComment, setPickupComment] = React.useState(null);
  const [pickupStatus, setPickupStatus] = React.useState(null);
  const [pickupAnswer, setPickupAnswer] = React.useState(null);

  // TODO retrieve from BE
  const statusList = [
    {value: '-1', label: 'Rechazada'},
    {value: '1', label: 'Pendiente de confirmar'},
    {value: '2', label: 'Confirmada'},
    {value: '3', label: 'Entregada/Recogida'},
  ]

  useEffect(() => {
    setPickupStatus(pickupData.STATUS_ID)
    setPickupComment(pickupData.COMMENT)
  }, [pickupData])

  const handleStatusChange = (input) => {
    setPickupStatus(input.value)
  }

  const handleOnCreate = () => {
    onCreate &&
    onCreate(pickupData.ID, pickupStatus, pickupComment, pickupAnswer);
  };

  const handleOnClose = () => {
    onClose && onClose();
  }

  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Actulizar recogida</h4>
          </div>

          <div className="modal-body">
            <form role="form" className="form form-horizontal form-groups-bordered" autoComplete="off" style={{height: '380px'}}>
              <div className="form-group col-xs-12">
                <div className="col-sm-3 text-right">
                  <label
                    htmlFor="status"
                    className="control-label bold"
                  >
                  Estado
                </label>
                </div>
                <div className="col-sm-9">
                  <Select
                    id="status"
                    name="SATUS"
                    options={statusList}
                    virtualized={true}
                    value={pickupStatus}
                    style={{width: '100%', padding:0}}
                    hideLabel={true}
                    onChange={handleStatusChange}
                    dimensions={{
                      label: 0,
                      element: 12
                    }}
                    notSaveInOrder
                  />
                </div>
              </div>

              <div className="form-group col-xs-12">
                <Input
                  label={"Respuesta"}
                  type={"text"}
                  id="pick-up-answer"
                  className="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setPickupAnswer(v)}
                  placeholder={"Escriba una respuesta para el cliente"}
                  value={pickupAnswer}
                  noFormGroup
                  isolateComponent
                />
              </div>

              <div className="form-group col-xs-12">
                <Input
                  label={"Comentario"}
                  type={"text"}
                  id="pick-up-description"
                  className="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setPickupComment(v)}
                  placeholder={pickupComment}
                  value={pickupComment}
                  disabled={true}
                  noFormGroup
                  isolateComponent
                />
              </div>
              <div className="form-group col-xs-12">
                <div className="col-xs-4 col-sm-3 text-right">
                  <label className='control-label bold'>Pedido:</label>
                </div>
                <div className="col-xs-8 col-sm-8 text-primary" style={{paddingTop: 7}}>
                  <p>{ pickupData.ORDER_PUBLIC_ID || "-" }</p>
                </div>
              </div>
              <div className="form-group col-xs-12">
                <div className="col-xs-4 col-sm-3 text-right">
                  <label className='control-label bold'>Datos cliente:</label>
                </div>
                <div className="col-xs-8 col-sm-8 text-primary" style={{paddingTop: 7}}>
                  <p>{ pickupData.CUSTOMER_NAME || "-" }</p>
                  <p>{ pickupData.CLINIC_NAME || "-" }</p>
                  <p>{ pickupData.TELEFONO || "-" }</p>
                  <p>{ pickupData.ADDRESS || "-" }</p>
                  <p>{ pickupData.POSTAL_CODE || "-" }</p>
                  <p>{ pickupData.CITY || "-" } - {pickupData.COUNTRY || "-" }</p>
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              onClick={() => handleOnCreate()}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};