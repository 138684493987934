import React from 'react'
import { default as Tooltip } from 'react-tooltip'
import {
  PRICE_IVA,
  PRICE_IVA_ARREGLOS
} from '../../../../core/constants/constants'
import {
  ORDER_STATUS,
  ORDER_TYPE,
  USER_ROLES,
  URGENCY_STATUS_LITERALS
} from '../../../../core/constants/roles'
import { removeObjectWithId } from '../../../../core/utils/array'
import { formatDate, formatTime } from '../../../../core/utils/date'
import { calculatePriceWithIva } from '../../../../core/utils/price'
import { sortCreatedOrder } from '../../../../core/utils/sort'
import { ChangeMoldeStatusCheckbox } from '../../components/change-molde-status-checkbox'
import { FavoriteOrderButton } from '../../components/favorite-order-button'
import UrgencyStatusBadge from "../../../../components/urgency-status-badge/urgency-status-badge";
import { getTableCommands } from '../cells/table-cell'
import { getFixOrderTableCommands } from '../cells/table-cell-fix-orders'

export const getBaseColumn = (id, name, selector, sortable = true) => ({
  id,
  name,
  selector,
  sortable
})

export const getIdLoteColumn = (idRol) => ({
  ...getBaseColumn('lote', 'Nº Lote', 'ID_SERIES', true),
  cell: (row) => {
    const isDuplicated = () =>
      row.FROM_ID !== '0' && row.ORDER_TYPE === ORDER_TYPE.NEW
    const isFixOrder = () =>
      row.FROM_ID !== '0' && row.ORDER_TYPE === ORDER_TYPE.FIX
    return (
      <div
        className={`${
          row.DATE_DIFF > 6 &&
          row.STATUS !== ORDER_STATUS.SENDED &&
          row.STATUS !== ORDER_STATUS.FINISHED &&
          row.STATUS !== ORDER_STATUS.DELETED &&
          idRol !== USER_ROLES.CLIENT
            ? 'red'
            : ''
        }`}
      >
        {row.ID_SERIES}
        {isDuplicated() ? <i className="entypo-docs"></i> : ''}
        {isFixOrder() ? <i className="entypo-ticket" title="arreglo"></i> : ''}
      </div>
    )
  }
})

export const getAllColumns = (idRol, setState, refreshTable) => {
  let columns = [
    {
      ...getBaseColumn(
        'favorite',
        <i className="entypo-star"></i>,
        'favorite',
        false
      ),
      cell: (row) =>
        row.ORDER_TYPE !== ORDER_TYPE.FIX ? (
          <FavoriteOrderButton ukey={row.UKEY} />
        ) : (
          <></>
        ),
      width: '25px'
    },
    {
      ...getIdLoteColumn(idRol)
    },
    {
      ...getBaseColumn('clinica', 'Clinica', 'CLINIC_NAME', true)
    },
    {
      ...getBaseColumn('name', 'Nombre', 'NOMBRE_PACIENTE', true)
    },
    {
      ...getBaseColumn('surname', 'Apellidos', 'APELLIDOS_PACIENTE', true)
    },
    {
      ...getBaseColumn('materialBase', 'Material base', 'M_BASE', true)
    },
    {
      ...getBaseColumn('status', 'Estado', 'STATUS_NAME', true),
      cell: (row) => (
        <div className={`status-${row.STATUS}`}>{row.STATUS_NAME}</div>
      )
    },
    {
      ...getBaseColumn('urgency', 'Urgencia', 'URGENCY_STATUS', true),
      cell: (row) => (
        row.URGENCY_STATUS !== null
          ? <UrgencyStatusBadge urgencyStatusId={row.URGENCY_STATUS} urgencyStatus={URGENCY_STATUS_LITERALS[row.URGENCY_STATUS]}/>
          : <></>
      )
    },
    {
      ...getBaseColumn('price', 'Precio', 'PRICE', true),
      cell: (row) => {
        const iva =
          row.ORDER_TYPE === ORDER_TYPE.FIX ? PRICE_IVA_ARREGLOS : PRICE_IVA
        return `${calculatePriceWithIva(row.PRICE, row.PRICE_DISCOUNT, iva)}€`
      }
    },
    {
      ...getBaseColumn('createDate', 'Fecha creación', 'FECHA_CREACION', true),
      cell: (row) =>
        row.STATUS === ORDER_STATUS.PROPOSAL
          ? formatTime(row.FECHA_CREACION)
          : formatTime(row.FECHA_DISENO),
      sortFunction: sortCreatedOrder
    },
    {
      ...getBaseColumn(
        'deliveryDate',
        <div>
          Fecha de envio estimada
          <i data-tip data-for="tooltip" className="entypo-info-circled"></i>
          <Tooltip id="tooltip" effect="solid" place="top">
            <p>
              Feetsoluter,
              <br /> recuerda que es una fecha estimada de salida del taller,
              luego hay que añadir
              <br />
              las 24/48h de envío del transportista, o el Km extra dependiendo
              de vuestra ubicación.
              <br />
              Gracias por confiar en nosotros
            </p>
          </Tooltip>
        </div>,
        'DELIVERY_DATE',
        true
      ),
      cell: (row) => (
        <>
          {row.DELIVERY_DATE ? (
            <>
              {formatDate(row.DELIVERY_DATE)}{' '}
              {row.DELIVERY_DATE_MODIFIED === '1' &&
                (idRol === USER_ROLES.ADMIN ||
                  idRol === USER_ROLES.DESIGNER) && (
                  <i className="entypo-alert" style={{ color: '#981b1b' }}></i>
                )}
            </>
          ) : null}
        </>
      )
    },
    {
      ...getBaseColumn('molde', 'Molde', 'MOLDE', false),
      cell: (row) => (
        <ChangeMoldeStatusCheckbox data={row} onChange={refreshTable} />
      )
    },
    {
      cell: (row) => {
        const currentOrderType = row.ORDER_TYPE
        const TABLE_COMMAND = {
          [ORDER_TYPE.NEW]: getTableCommands,
          [ORDER_TYPE.FIX]: getFixOrderTableCommands
        }
        return TABLE_COMMAND[currentOrderType](row, idRol, setState)
      },
      ignoreRowClick: true,
      //allowOverflow: true,
      sortable: false,
      button: true,
      width: '150px'
    }
  ]

  //only show in client
  if (idRol === USER_ROLES.CLIENT) {
    columns = removeObjectWithId(columns, 'clinica')
  }

  //only ROLES different different CLIENT ADMIN AND DESIGNER can see the deliveryDate column
  if (
    idRol !== USER_ROLES.CLIENT &&
    idRol !== USER_ROLES.ADMIN &&
    idRol !== USER_ROLES.DESIGNER
  ) {
    columns = removeObjectWithId(columns, 'deliveryDate')
  }

  // only ROLES different ADMIN can see the price column
  if (idRol !== USER_ROLES.ADMIN) {
    columns = removeObjectWithId(columns, 'price')
  }

  // only ROLES different ADMIN and DESIGN can see this columns
  if (idRol !== USER_ROLES.ADMIN && idRol !== USER_ROLES.DESIGNER) {
    columns = removeObjectWithId(columns, 'molde')
  }

  // only ADMIN CLIENT Y ADMIN can see this column
  if (![USER_ROLES.ADMIN, USER_ROLES.CLIENT, USER_ROLES.DESIGNER].includes(idRol)) {
    columns = removeObjectWithId(columns, 'urgency')
  }

  return columns
}
