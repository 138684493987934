import React from "react";
import {ORDER_STATUS, URGENT_ORDER_REQUEST_STATUS, USER_ROLES} from "../../../core/constants/roles";
import "../orders.css";
import { ChangeDeliveryDateRow } from "./rows/changeDeliveryDate/changeDeliveryDate";
import { debounce } from "../../../core/utils/debounce";

export const ClientModal = ({ idRol, show, onClose, onNewDeliveryDate, onRequestUrgency, data }) => {
  const currentStatus = data.STATUS;
  const currentDeliveryDate = data.DELIVERY_DATE;
  const deliveryDateModified = data.DELIVERY_DATE_MODIFIED;

  const [urgencyRequestComment, setUrgencyRequestComment] = React.useState(null)

  const handleOnClose = () => {
    onClose && onClose();
  };

  const handlerOnChangeDate = (newDate) => {
    onNewDeliveryDate && onNewDeliveryDate(newDate);
  };

  const handleOnRequestUrgency = () => {
    onRequestUrgency(urgencyRequestComment)
  }

  const getIsPossibleChangeDateToSend = () => {
    return (
      currentStatus === ORDER_STATUS.DESIGN ||
      currentStatus === ORDER_STATUS.FABRICATION
    );
  };

  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => handleOnClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Opciones del pedido</h4>
          </div>
          <div className="modal-body">
            {getIsPossibleChangeDateToSend() && (
              <ChangeDeliveryDateRow
                ukey={data.UKEY}
                currentDeliveryDate={currentDeliveryDate}
                onChange={handlerOnChangeDate}
                disabled={deliveryDateModified === "1"}
              />
            )}

            {[USER_ROLES.CLIENT, USER_ROLES.ADMIN].includes(idRol) && data.URGENCY_STATUS === null && currentStatus === ORDER_STATUS.DESIGN ? (
              <div>
                <div className="fifty" style={{verticalAlign: "top"}}>Solicitar urgencia:</div>
                <div className="urgency-container">
                  <textarea
                    id="urgency-request-comment"
                    className="input-control"
                    placeholder="Especifique el motivo de la urgencia"
                    onChange={(e) => {debounce(setUrgencyRequestComment(e.target.value))}}
                    name="urgency-request-comment"
                  />
                  <div className="urgency-button-row">
                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={!urgencyRequestComment?.trim().length}
                      onClick={() => handleOnRequestUrgency()}
                    >
                      <i className="entypo-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {[USER_ROLES.CLIENT, USER_ROLES.ADMIN].includes(idRol) && data.URGENCY_STATUS !== null ? (
              <div>
                <div className="fifty" style={{verticalAlign: "top"}}>Urgencia:</div>
                <div className="urgency-container">
                  <div className="urgency-comment">
                    <p className="text-primary">{data.URGENCY_COMMENT}</p>
                  </div>
                  {data.URGENCY_STATUS == URGENT_ORDER_REQUEST_STATUS.ACCEPTED && (
                    <div className="urgency-comment">
                      <p className="text-success">Aprobado</p>
                    </div>
                  )}
                  {data.URGENCY_STATUS == URGENT_ORDER_REQUEST_STATUS.REJECTED && (
                    <div className="urgency-comment">
                      <p className="text-danger">Rechazado</p>
                      <p className="text-primary">{data.URGENCY_REJECTION_COMMENT}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : null}

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
