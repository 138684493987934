export const USER_ROLES = {
  ADMIN: '99',
  DESIGNER: '4',
  QA: '3',
  FABRICATION: '2',
  CLIENT: '1'
}

export const ORDER_STATUS = {
  DESIGN: '1',
  FABRICATION: '2',
  QA: '3',
  FINISHED: '4',
  PROPOSAL: '5',
  DELETED: '6',
  SENDED: '7',
  DELIVERED: '8',
  ARREGLO: '9'
}

export const ORDER_TYPE = {
  NEW: '1',
  FIX: '2'
}

export const URGENT_ORDER_REQUEST_STATUS = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: -1
}

export const URGENCY_STATUS_LITERALS = {
  [URGENT_ORDER_REQUEST_STATUS.PENDING]: "Pdte revisión",
  [URGENT_ORDER_REQUEST_STATUS.ACCEPTED]: "Aceptado",
  [URGENT_ORDER_REQUEST_STATUS.REJECTED]: "Rechazado"
}