import React, { Component } from 'react';
import propTypes from 'prop-types';

import './urgency-status-badge.css';

class UrgencyStatusBadge extends Component {

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className={`urgency-status-badge urgency-status-badge__${this.props.urgencyStatusId}`}>{this.props.urgencyStatus}</div>
    )
  }
}

UrgencyStatusBadge.propTypes = {
  urgencyStatusId: propTypes.string,
  urgencyStatus: propTypes.string,
};

UrgencyStatusBadge.defaultProps = {
  urgencyStatusId: null,
  urgencyStatus: '',
};

export default UrgencyStatusBadge