import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ModalVideo from 'react-modal-video';
import Table from '../../components/table/table';
import { getTutorials, setTutorial, deleteTutorial, updateTutorial } from '../../resources/services';
import { TutorialModal } from './modals/tutorial-modal';


import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import Breadcrumb from "../../components/breadcrumb/breadcrumb";

class ManageTutorials extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loadError: false,
      data:[],
      showTutorialModal: false,
      selectedTutorialData: null,
      isVideoOpen: false,
      videoId: null
    };

    this.handleOnCloseTutorialModal = this.handleOnCloseTutorialModal.bind(this)
    this.handleTutorialCreation = this.handleTutorialCreation.bind(this)

    this.columns = [
      {
        name: 'Título',
        selector: 'TITLE',
        sortable: true,
      },
      {
        name: 'Vídeo',
        selector: 'YOUTUBE_URL',
        sortable: false,
        cell: (row) =>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.playVideo(row.YOUTUBE_URL)}
          ><i className="entypo-play">{row.YOUTUBE_URL}</i>
          </button>

      },
      {
        name: 'Comentario',
        selector: 'COMMENT',
        sortable: false
      },
      {
        name: 'Acciones',
        cell: (row) => {
          return [
            <button
              type="button"
              className="btn btn-default"
              style={{ marginRight: '10px' }}
              onClick={() => this.editTutorial(row)}
            ><i className="entypo-pencil"></i>
            </button>,
            <button
              type="button"
              className="btn btn-red"
              onClick={() => this.deleteTutorial(row.ID)}
            ><i className="entypo-trash"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];
  }

  componentDidMount() {
    this.loadTutorials()
  }

  playVideo(videoId) {
    this.setState({
      isVideoOpen: true,
      videoId
    })
  }

  editTutorial(tutorialData) {
    this.setState({showTutorialModal: true, selectedTutorialData: tutorialData})
  }

  deleteTutorial(tutorialId){
    window.confirm(`Estás seguro de que deseas eliminar el tutorial?`) && deleteTutorial(tutorialId)
      .then((result) => {
        if(result.response && result.deleted != 0){
          this.loadTutorials()
        }
      })
  }

  loadTutorials() {
    this.setState({ loading: true })
    getTutorials()
      .then((result) => {
        if(result.response){
          this.setState({
            data: result.tutorials
          })
        }else{
          toast("Se ha producido un error al realizar la consulta", {type: toast.TYPE.ERROR})
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleOnCloseTutorialModal = () => {
    this.setState({ showTutorialModal: false, selectedTutorialData: null })
  }

  handleTutorialCreation = (tutorialId, title, youtubeUrl, comment) => {
    this.setState({ loading: true })
    const reqPromise = tutorialId
      ? updateTutorial(tutorialId, title, youtubeUrl, comment)
      : setTutorial(title, youtubeUrl, comment)

    reqPromise.then((result) => {
      this.setState({ loading: false, showTutorialModal: false })
      this.loadTutorials()
      if (result) {
        if (result.created == 1 || result.updated == 1) {
          toast(`El tutorial se ha ${tutorialId ? 'actualizado' : 'creado'} con éxito`, {
            type: toast.TYPE.SUCCESS
          })
        } else {
          toast('Se ha producido un error', {
            type: toast.TYPE.ERROR
          })
        }
      }
    })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Tutoriales y FAQs", url: "tutoriales-gestion"},
          ]}
        />
        <div className="text-right row-control">
          <button className="btn btn-primary" onClick={() => this.setState({showTutorialModal: true, selectedTutorialData: null})}>
            <i className="entypo-plus"></i> Añadir tutorial
          </button>
        </div>
        <Table
          keyFilter={'TITLE'}
          data={this.state.data}
          columns={this.columns}
          title="Tutoriales y FAQs"
          pagination
        />
        {this.state.showTutorialModal && (
          <TutorialModal
            show={this.state.showTutorialModal}
            onClose={this.handleOnCloseTutorialModal}
            onCreate={this.handleTutorialCreation}
            tutorialData={this.state.selectedTutorialData}
          />
        )}
        <ModalVideo
          channel="youtube"
          youtube={{ mute: 0, autoplay: 0 }}
          isOpen={this.state.isVideoOpen}
          videoId={this.state.videoId}
          onClose={() => this.setState({isVideoOpen: false})}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(ManageTutorials)