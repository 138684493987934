
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { signIn } from './../../resources/services';

// Actions
import { updateAppAction } from './../../redux/actions/app';

// Img
import Logo from '../../assets/images/logo-login.png';

// css
import './login.css';

function Login(props) {

  let usernameRef = React.createRef();
  let passwordRef = React.createRef();
  let history = useHistory();

  //Didload
  useEffect(() => {
    let bodyClass = window.document.body.className
    bodyClass = bodyClass.replace("loaded login-form-fall-init", "")
    window.document.body.className = bodyClass + " loaded login-form-fall-init"
    usernameRef.current.focus();
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSignIn()
    }
  }

  const setPercentage = percentage => {
    let bodyClass = window.document.body.className
    const progressBar = window.document.getElementsByClassName("pgb")[0]
    const percent = window.document.getElementById("percent")
    
    // jQuery commands for framework neon theme 
    window.$(".login-page").addClass('logging-in'); // This will hide the login form and init the progress bar			
		// Hide Errors
    window.$(".form-login-error").slideUp('fast');
    progressBar.style.width = percentage+"%"
    percent.innerHTML = percentage+"%"
    if(percentage === 0){
      bodyClass = bodyClass.replace("loggin-in", "")
      window.$(".form-login-error").slideDown('fast');
      window.$(".login-page").removeClass('logging-in');
      progressBar.style.width = 10+"%"
      percent.innerHTML = 10+"%"
    }
  }

  const onSignIn = () => {
    const user = usernameRef.current.value;
    const password = passwordRef.current.value;

    //email
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!re.test(String(user).toLowerCase())){
      toast('Formato del email incorrecto',{type: toast.TYPE.ERROR})
      return false
    }
    var random_pct = 25 + Math.round(Math.random() * 30);
    setPercentage(40 + random_pct);
          
    signIn(user, password)
      .then((response) => {
        if(response && response.login){
          setPercentage(100);
          setTimeout(() => {
            window.localStorage.setItem('ukey', response.ukey);
            props.updateApp({
              logged: true,
              idRol: response.r,
              idUser: response.u,
              logo: response.l,
              name: response.n,
            });
            window.$(".form-login-error").slideDown('fast');
            window.$(".login-page").removeClass('logging-in');
            window.$("body").removeClass('logging-in');
            switch (response.r) {
              case "2":
                history.push('/fabricacion');
                break;
              case "3":
                history.push('/control-de-calidad');
                break;
              default:
                history.push('/landing');
                break;
            }
          },1500)
        } else {
          setTimeout(() => {
            setPercentage(0);
		        // Hide Errors
            //window.$(".form-login-error").slideDown('fast');
            toast('El usuario o contraseña no es correcto.',{type: toast.TYPE.ERROR})
          },1500)
        }
      });
  };
  
  return (
    <div>
      <div className="login-container">
	
        <div className="login-header login-caret">
          
          <div className="login-content">
            
            <a href="#" className="logo">
              <img src={Logo} alt="Logo" width="250"/>
            </a>

            
            <div className="login-progressbar-indicator">
              <h3 id="percent">10%</h3>
              <span>logging in...</span>
            </div>
          </div>
          
        </div>
        
        <div className="login-progressbar">
          <div className="pgb"></div>
        </div>
        
        <div className="login-form">
          
          <div className="login-content">
            <form method="post" role="form" id="form_login" autoComplete="off">
              <p className="description" style={{marginBottom: '25px'}}>Identifícate para acceder a tu area personal.</p>
              
              <div className="form-group">
                
                <div className="input-group">
                  <div className="input-group-addon">
                    <i className="entypo-user"></i>
                  </div>
                  
                  <input ref={usernameRef} type="text" className="form-control" name="username" id="username" placeholder="Email" autoComplete="on" onKeyDown={handleKeyDown} />
                </div>
                
              </div>
              
              <div className="form-group">
                
                <div className="input-group">
                  <div className="input-group-addon">
                    <i className="entypo-key"></i>
                  </div>
                  
                  <input ref={passwordRef} type="password" className="form-control" name="password" id="password" placeholder="Contraseña" autoComplete="off" onKeyDown={handleKeyDown} />
                </div>
              
              </div>
              
              <div className="form-group" style={{width: '100%'}}>
                <button type="button" className="btn btn-primary btn-block btn-login" onClick={onSignIn}>
                  Entrar
                  <i className="entypo-login"></i>
                </button>
              </div>
              
            </form>
            
          </div>
          
        </div>
        
      </div>
    </div>
    );
  }

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = {
  updateApp: updateAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);