import React from "react";
import DatePicker from "react-multi-date-picker";
import { FORMAT_DATE, FORMAT_SERVER_DATE } from '../../../core/constants/constants'

export const NewDayOffModal = ({ show, onClose, onCreate }) => {

  const [dayOffDate, setDayOffDate] = React.useState(null);
  const [dayOffDescription, setDayOffDescription] = React.useState(null);

  const handleOnCreate = () => {
    onCreate &&
    onCreate(dayOffDate.format(FORMAT_SERVER_DATE.toUpperCase()), dayOffDescription);
  };
  const handleOnClose = () => {
    onClose && onClose();
  }
  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Crear festivo</h4>
          </div>

          <div className="modal-body">
            <form role="form" className="form-groups-bordered" autoComplete="off" style={{height: '12rem'}}>
              <div className="form-group" style={{width: '100%'}}>
                <label htmlFor="field-1" className="col-sm-3 control-label">Fecha</label>
                  <DatePicker
                    value={dayOffDate}
                    render={<DateInput/>}
                    format={FORMAT_DATE.toUpperCase()}
                    onChange={(newDate) => setDayOffDate(newDate)}
                  />
              </div>
              <div className="form-group" style={{width: '100%'}}>
                <label htmlFor="field-2" className="col-sm-3 control-label">Descripción</label>
                <input
                  type="text"
                  name="day-off-description"
                  className="form-control col-sm-9"
                  id="field-2"
                  placeholder="Descripcíon del festivo"
                  value={dayOffDescription}
                  maxLength={150}
                  onChange={(e) => setDayOffDescription(e.target.value)}
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              disabled={!dayOffDate || !dayOffDescription}
              onClick={() => handleOnCreate()}
            >
              Crear
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

const DateInput = ({
  openCalendar,
  value,
  disabled,
  handlerOnChange
}) => {
  return (
    <input
      type={'text'}
      onFocus={() => {
        openCalendar && openCalendar()
      }}
      defaultValue={value}
      onChange={handlerOnChange}
      className={disabled ? 'input-control disabled' : 'input-control'}
      style={{ marginLeft: '1rem', borderRadius: '3px', height: '3rem' }}
    />
  )
}
