import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";
import Table from "../../../components/table/table";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import { createNewBatch, getMaterialStock, getPrices } from "../../../resources/services";
import { TracingModal } from "./modals/tracingModal";
import { CreateBatchModal } from "./modals/createBatchModal";

class MaterialesStock extends Component {

  constructor(props){
    super(props);
    this.state = {
      loadError: false,
      data: [],
      stockData: [],
      stockInfo: [],
      materialInfo: {},
      showTracingModal: false,
      showCreateBatchModal: false
    };

    this.showTracingModal = this.showTracingModal.bind(this)
    this.handleNewBatchCreation = this.handleNewBatchCreation.bind(this)
    this.loadMaterialStock = this.loadMaterialStock.bind(this)

    this.columns = [
      {
        name: "Núm de lote",
        selector: "BATCH_NUMBER",
        sortable: true,
      },
      {
        name: "Cantidad inicial",
        selector: "QUANTITY",
        sortable: true
      },
      {
        name: "Consumo por ud.",
        selector: "CONSUMPTION_BY_PRODUCED_UNIT",
        sortable: true
      },
      {
        name: "Cantidad consumida",
        sortable: true,
        cell: (row) => row.QUANTITY - row.STOCK_AVAILABLE
      },
      {
        name: "Cantidad disponible",
        selector: "STOCK_AVAILABLE",
        sortable: true
      },
      {
        cell: (row) => {
          return [
            <>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => this.showTracingModal(row)}><i className="entypo-eye"></i>
              </button>
            </>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ];

  }

  componentDidMount() {
    const materialId = this.props.match.params.materialId;
    getPrices()
      .then((result) => {
        if (result.response == 0) {
          toast("Se ha producido un error al realizar la consulta", { type: toast.TYPE.ERROR })
          return
        }

        this.setState({materialInfo: result.materials.filter(m => m.ID == materialId).pop()})
      })

    this.loadMaterialStock()
  }

  loadMaterialStock () {
    const materialId = this.props.match.params.materialId;
    getMaterialStock(materialId)
      .then((result) => {
        if (result.response){
          const stockData = {}
          result.stock.forEach(s => {
            if (!stockData[s.BATCH_NUMBER]) {
              stockData[s.BATCH_NUMBER] = s
            }
          })
          this.setState({
            data: result.stock,
            stockData: Object.values(stockData)
          })
        } else {
          toast("Se ha producido un error al realizar la consulta", { type: toast.TYPE.ERROR })
        }
      })
  }

  showTracingModal (row) {
    const stockInfo = this.state.data.filter(s => s.BATCH_NUMBER === row.BATCH_NUMBER)
    this.setState({ stockInfo, showTracingModal: true })
  }

  handleNewBatchCreation (batchNumber, qty, consumption) {
    const materialId = this.props.match.params.materialId
    createNewBatch(materialId, batchNumber, qty, consumption)
      .then((response) => {
        if (response.response && response.created) {
          this.loadMaterialStock()
          toast("Se ha añadido el nuevo lote", { type: toast.TYPE.SUCCESS })
        } else {
          toast("Se ha producido un error", { type: toast.TYPE.ERROR })
        }
      })
      .finally(() => this.setState({ showCreateBatchModal: false }))
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Precios", url: "precios"},
            {title: "Stock", url: "stock"},
          ]}
        />
        <div className="text-right row-control">
          <button className="btn btn-primary" onClick={() => this.setState({showCreateBatchModal: true})}>
            <i className="entypo-plus"></i> Añadir lote
          </button>
        </div>
        <Table
          keyFilter={"NAME"}
          data={this.state.stockData}
          columns={this.columns}
          title={`Lotes material: ${this.state.materialInfo && this.state.materialInfo.NAME}`}
          pagination
        />
        {this.state.showTracingModal && (
          <TracingModal
            show={this.state.showTracingModal}
            onClose={() => {this.setState({ showTracingModal: false, stockInfo: {} })}}
            info={this.state.stockInfo}
          />
        )}
        {this.state.showCreateBatchModal && (
          <CreateBatchModal
            show={this.state.showCreateBatchModal}
            onClose={() => { this.setState({ showCreateBatchModal: false }) }}
            onCreateNewBatch={(batchNumber, qty, consumption) => {
              this.handleNewBatchCreation(batchNumber, qty, consumption)
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default MaterialesStock;