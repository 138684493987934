import React, {useEffect} from "react";
import DatePicker from "react-multi-date-picker";
import { FORMAT_DATE, FORMAT_SERVER_DATE } from '../../../core/constants/constants'
import { getOrders } from '../../../resources/services'
import Select from '../../../components/select/select'
import Input from "../../../components/input/input";

export const NewPickupModal = ({ show, onClose, onCreate }) => {

  const today = new Date()

  const [pickupDate, setPickUpDate] = React.useState(null);
  const [pickupComment, setPickupComment] = React.useState(null);
  const [pickupOrder, setPickupOrder] = React.useState(null);

  const [orderList, setOrderList] = React.useState([]);

  const loadOrders = () => {
    getOrders().then((result) => {
      if (result.response) {
        setOrderList(result.orders.map((data) => ({
          value: data.UKEY,
          label: `${data.ID_SERIES} - ${data.NOMBRE_PACIENTE} ${data.APELLIDOS_PACIENTE}`
        })))
      }
    })
  }

  useEffect(() => {
    loadOrders()
  }, [])

  const handleOrderChange = (input) => {
    setPickupOrder(input.value)
  }

  const handleOnCreate = () => {
    onCreate &&
    onCreate(pickupDate.format(FORMAT_SERVER_DATE.toUpperCase()), pickupComment, pickupOrder);
  };

  const handleOnClose = () => {
    onClose && onClose();
  }

  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Crear recogida</h4>
          </div>

          <div className="modal-body">
            <form role="form" className="form form-horizontal form-groups-bordered" autoComplete="off" style={{height: '150px'}}>
              <div className="form-group col-xs-12">
                <div className="col-sm-3 text-right">
                  <label className="control-label bold">Fecha</label>
                </div>
                <DatePicker
                  value={pickupDate}
                  render={<DateInput/>}
                  format={FORMAT_DATE.toUpperCase()}
                  minDate={today}
                  onChange={(newDate) => setPickUpDate(newDate)}
                  containerClassName={'col-sm-9'}
                />
              </div>

              <div className="form-group col-xs-12">
                <div className="col-sm-3 text-right">
                  <label
                    htmlFor="cliente"
                    className="control-label bold"
                  >
                  Pedido
                </label>
                </div>
                <div className="col-sm-9">
                  <Select
                    id="pedido"
                    name="ORDER_ID"
                    options={orderList}
                    virtualized={true}
                    value={pickupOrder}
                    style={{width: '100%', padding:0}}
                    hideLabel={true}
                    onChange={handleOrderChange}
                    dimensions={{
                      label: 0,
                      element: 12
                    }}
                    notSaveInOrder
                  />
                </div>
              </div>

              <div className="form-group col-xs-12">
                <Input
                  label={"Comentario"}
                  type={"text"}
                  id="day-off-description"
                  className="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setPickupComment(v)}
                  placeholder={pickupComment}
                  noFormGroup
                  isolateComponent
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              disabled={!pickupDate || !pickupComment || !pickupOrder}
              onClick={() => handleOnCreate()}
            >
              Crear
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => handleOnClose()}
            >
              Cerrar
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

const DateInput = ({
 openCalendar,
 value,
 disabled,
 handlerOnChange
}) => {
  return (
    <input
      type={'text'}
      onFocus={() => {
        openCalendar && openCalendar()
      }}
      defaultValue={value}
      onChange={handlerOnChange}
      className={disabled ? 'input-control disabled' : 'input-control'}
      style={{ borderRadius: '3px', height: '3rem' }}
    />
  )
}