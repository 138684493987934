import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { formatDate } from "../../core/utils/date";
import { USER_ROLES } from "../../core/constants/roles";
import { withQueryParamsHandler } from "../../hook/useQueryParams.hook";
import Table from '../../components/table/table';
import PickupStatusBadge from "../../components/pickup-status-badge/pickup-status-badge";
import { getPickups, setPickup, setPickupStatus } from "../../resources/services";
import { NewPickupModal } from "./modals/newPickupModal";
import { EditPickupModal } from "./modals/editPickupModal";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";


class Recogidas extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loadError: false,
      data: [],
      showCreatePickupModal: false,
      showEditPickupModal: false,
      pickupData: null,
      redirect: null
    };

    this.handleOnCloseCreatePickupModal = this.handleOnCloseCreatePickupModal.bind(this)
    this.handleNewPickupCreation = this.handleNewPickupCreation.bind(this)

    this.handleOnCloseEditPickupModal = this.handleOnCloseEditPickupModal.bind(this)
    this.handlePickupEdition = this.handlePickupEdition.bind(this)

    this.columns = [
      {
        id: 'created_at',
        name: 'Creado',
        selector: 'CREATED_AT',
        sortable: true,
        cell: (row) => {
          return formatDate(row.CREATED_AT)
        },
      }
    ]

    if (props.app.idRol == 99) {
      this.columns.push({
        name: 'Cliente',
        selector: 'CUSTOMER_NAME',
        sortable: true,
      })
    }

    this.columns.push(...[
      {
        name: 'Nº Lote',
        selector: 'ORDER_PUBLIC_ID'
      },
      {
        name: 'Fecha',
        selector: 'DATE',
        sortable: true,
        cell: (row) => {
          return formatDate(row.DATE)
        },
      },
      {
        name: 'Estado',
        selector: 'STATUS_ID',
        cell: (row) => {
          return <PickupStatusBadge pickupStatusId={row.STATUS_ID} pickupStatus={row.STATUS_NAME}/>
        },
        sortable: true,
      },
      {
        name: 'Comentario',
        selector: 'COMMENT'
      },
      {
        name: 'Respuesta',
        selector: 'ANSWER'
      }
    ]);
    if (props.app.idRol == 99) {
      this.columns.push({
        name: 'Acciones',
        cell: (row) => {
          return [
            <button
              type="button"
              className="btn btn-red"
              onClick={() => this.editPickup(row)}
            ><i className="entypo-pencil"></i>
            </button>
          ]
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      })
    }
  }

  componentDidMount() {
    this.loadPickUps()
  }

  loadPickUps() {
    this.setState({ loading: true })
    getPickups()
      .then((result) => {
        if(result.response){
          this.setState({
            data: result.pickups
          })
        }else{
          toast("Se ha producido un error al realizar la consulta", {type: toast.TYPE.ERROR})
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleOnCloseCreatePickupModal = () => {
    this.setState({ showCreatePickupModal: false })
  }

  handleNewPickupCreation = (date, description, order) => {
    this.setState({ loading: true })
    setPickup(date, description, order)
      .then((result) => {
        this.loadPickUps()
        if (result) {
          if (result.created == 1) {
            toast('La recogida se ha creado con éxito', {
              type: toast.TYPE.SUCCESS
            })
            if (this.props.app.idRol === USER_ROLES.CLIENT) {
              this.setState({
                redirect: true
              })
            }
          } else {
            toast('Se ha producido un error', {
              type: toast.TYPE.ERROR
            })
          }
        }
      })
      .finally(() => {
        this.setState({ loading: false, showCreatePickupModal: false })
      })
  }


  editPickup = (pickupData) => {
    this.setState({
      pickupData,
      showEditPickupModal: true
    })
  }

  handleOnCloseEditPickupModal = () => {
    this.setState({
      pickupData: null,
      showEditPickupModal: false,
    })
  }

  handlePickupEdition = (pickupId, newStatus, newComment, answer) => {
    this.setState({ loading: true })
    setPickupStatus(pickupId, newStatus, answer)
      .then((result) => {
        this.loadPickUps()
        if (result) {
          if (result.updated == 1) {
            toast('La recogida se ha actualizado con éxito', {
              type: toast.TYPE.SUCCESS
            })
          } else {
            toast('Se ha producido un error', {
              type: toast.TYPE.ERROR
            })
          }
        }
      })
      .finally(() => {
        this.setState({ loading: false, showEditPickupModal: false })
      })
  }

  render() {
    if( this.state.redirect) {
      return <Redirect push to="/landing" />
    }
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Recogidas", url: "recogidas"},
          ]}
        />
        <div className="text-right row-control">
          {[USER_ROLES.ADMIN, USER_ROLES.CLIENT].includes(this.props.app.idRol) && (
            <button className="btn btn-primary" onClick={() => this.setState({showCreatePickupModal: true})}>
              <i className="entypo-plus"></i> Solicitar recogida
            </button>
          )}
        </div>
        <Table
          keyFilter={'DATE'}
          data={this.state.data}
          columns={this.columns}
          title="Recogidas"
          defaultSortField={'created_at'}
          defaultSortAsc={false}
          pagination
        />
        {this.state.showCreatePickupModal && (
          <NewPickupModal
            show={this.state.showCreatePickupModal}
            onClose={this.handleOnCloseCreatePickupModal}
            onCreate={this.handleNewPickupCreation}
          />
        )}
        {this.state.showEditPickupModal && (
          <EditPickupModal
            show={this.state.showEditPickupModal}
            onClose={this.handleOnCloseEditPickupModal}
            onCreate={this.handlePickupEdition}
            pickupData={this.state.pickupData}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(withQueryParamsHandler(Recogidas))