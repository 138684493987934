import React from "react";
import Input from '../../../../components/input/input';

export const CreateBatchModal = ({ show, onClose, onCreateNewBatch }) => {

  const [batchNumber, setBatchNumber] = React.useState(null);
  const [quantity, setQuantity] = React.useState(0);
  const [consumption, setConsumption] = React.useState(0);
  const [batchNumberError, setBatchNumberError] = React.useState(null);
  const [quantityError, setQuantityError] = React.useState(null);
  const [consumptionError, setConsumptionError] = React.useState(null);

  const checkFormData = () => {
    let isFormDataValid = true
    if (batchNumber.trim().length > 0) {
      setBatchNumberError(null)
    } else {
      setBatchNumberError('Debe indicar un número de lote')
      isFormDataValid = false
    }

    if (quantity > 0) {
      setQuantityError(null)
    } else {
      setQuantityError('Cantidad de stock no válida')
      isFormDataValid = false
    }

    if (consumption > 0) {
      setConsumptionError(null)
    } else {
      setConsumptionError('Consumo por plantilla no válido')
      isFormDataValid = false
    }

    if (consumption < quantity) {
      setConsumptionError(null)
    } else {
      setConsumptionError('El consumo por plantilla no puede ser mayor a la cantidad de stock del lote')
      isFormDataValid = false
    }

    return isFormDataValid
  }

  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Nuevo lote</h4>
          </div>

          <div className="modal-body">
            <form role="form" className="row form form-horizontal form-groups-bordered" autoComplete="off">
              <div className="form-group col-xs-12">
                <Input
                  label={"Núm. Lote"}
                  type={"text"}
                  id="num-lote"
                  inputClass="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setBatchNumber(v)}
                  placeholder={"Lote nº"}
                  value={batchNumber}
                  required
                  noFormGroup
                  isolateComponent
                />
                {batchNumberError && (
                  <p className={"text-danger"}>{batchNumberError}</p>
                )}
              </div>
              <div className="form-group col-xs-12">
                <Input
                  label={"Unidades"}
                  type={"number"}
                  id="qty"
                  inputClass="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setQuantity(v)}
                  value={quantity}
                  required
                  noFormGroup
                  isolateComponent
                />
                {quantityError && (
                  <p className={"text-danger"}>{quantityError}</p>
                )}
              </div>
              <div className="form-group col-xs-12">
                <Input
                  label={"Consumo por plantilla"}
                  type={"number"}
                  id="consumption"
                  inputClass="form-control"
                  dimensions={{
                    label: 3,
                    element: 9
                  }}
                  onChange={(v) => setConsumption(v)}
                  value={consumption}
                  requireds
                  noFormGroup
                  isolateComponent
                />
                {consumptionError && (
                  <p className={"text-danger"}>{consumptionError}</p>
                )}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-green"
              data-dismiss="modal"
              disabled = {!batchNumber || !quantity || !consumption}
              onClick={() => {
                checkFormData() && onCreateNewBatch && onCreateNewBatch(batchNumber, quantity, consumption)
              }}
            >
              Crear
            </button>
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={() => onClose && onClose()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}