import React, { Component, Fragment } from "react";
import { toast } from "react-toastify";

import { getBaseColumn } from "../../orders/utils/columns/columns.utils";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Table from "../../../components/table/table";
import { SpinnerLoading } from "../../../components/loading/loading";
import { getTracingByBatch } from "../../../resources/services";


class MaterialesTrazabilidad extends Component {

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      data: [],
    }

    this.columns = [
      { ...getBaseColumn('batch_number', "Núm de lote", "BATCH_NUMBER") },
      { ...getBaseColumn('order_id', "Pedido", "ORDER_ID", true) },
      { ...getBaseColumn('clinica', 'Clinica', 'CLINIC_NAME', true) }
    ]

    this.handleDownloadCsv = this.handleDownloadCsv.bind(this)
  }

  componentDidMount () {
    const batchNumber = this.props.match.params.batchNumber
    this.loadData(batchNumber)
  }

  loadData (batchNumber) {
    this.setState({ loading: true })
    getTracingByBatch(batchNumber)
      .then(response => {
        if (response.response) {
          this.setState({ data: response.tracing })
        } else {
          toast("Se ha producido un error", { type: toast.TYPE.ERROR })
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  downloadCsvReport (batchNumber) {
    this.setState({ loading: true })
    getTracingByBatch(batchNumber, "csv")
      .then((response) => {
        if (!response.response) {
          toast("Se ha producido un error", { type: toast.TYPE.ERROR })
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleDownloadCsv () {
    const batchNumber = this.props.match.params.batchNumber
    this.downloadCsvReport(batchNumber)
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          section={[
            {title: "Precios", url: "precios"},
            {title: "Trazabiliad", url: "trazabilidad"}
          ]}
        />

        <div className="text-right row-control">
          <button className="btn btn-primary" disabled={!this.state.data.length} onClick={() => this.handleDownloadCsv()}>
            <i className="entypo-download"></i> Descargar CSV
          </button>
        </div>

        <Table
          keyFilter={"ORDER_ID"}
          data={this.state.data}
          columns={this.columns}
          title={`Trazabiliad lote: ${""}`}
          noDataComponent={"No hay pedidos para este lote"}
          progressPending={this.state.loading}
          progressComponent={<SpinnerLoading />}
          pagination
        />

      </Fragment>
    )
  }
}

export default MaterialesTrazabilidad;