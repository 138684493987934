import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ModalVideo from 'react-modal-video';
import { getTutorials } from '../../resources/services';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';

import '../../../node_modules/react-modal-video/css/modal-video.min.css';
import styled from "styled-components";

const TextField = styled.input`
  height: 32px;
  width: 300px;
  border-radius: 3px;
  border: 1px solid #e5e5e5;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }
`;

class Tutorials extends Component {

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loadError: false,
      data:[],
      isVideoOpen: false,
      videoId: null,
      filter: null
    };
  }

  componentDidMount() {
    this.loadTutorials()
  }

  loadTutorials() {
    this.setState({ loading: true })
    getTutorials()
      .then((result) => {
        if(result.response){
          this.setState({
            data: result.tutorials
          })
        }else{
          toast("Se ha producido un error al realizar la consulta", {type: toast.TYPE.ERROR})
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb section={[{ title: "Tutoriales y FAQs" }]} />

        <header className="row">
          <div className={"col-xs-12 col-lg-9"}>
            <h1>Tutoriales y FAQs</h1>
          </div>
          <div className={"col-xs-12 col-lg-3"}>
            <TextField
              id="search"
              type="search"
              role="search"
              placeholder="Buscador"
              value={this.state.filter}
              onChange={(e) => this.setState({ filter:e.target.value })}
            />
          </div>
        </header>

        {this.state.data.filter(tutorial => this.state.filter ? tutorial.TITLE.includes(this.state.filter) || tutorial.COMMENT.includes(this.state.filter) : true).map(tutorial => (
          <div className="panel panel-primary">
            <div className="panel-heading">
              <span className="panel-title">{tutorial.TITLE}</span>
            </div>
            <div className="panel-body">
              {tutorial.COMMENT && <p>{tutorial.COMMENT}</p>}
              <button className="btn btn-primary" onClick={() => this.setState({videoId: tutorial.YOUTUBE_URL, isVideoOpen: true})}>
                <i className="entypo-play"></i> Ver vídeo
              </button>
            </div>
          </div>
        ))}
        <ModalVideo
          channel="youtube"
          youtube={{ mute: 0, autoplay: 0 }}
          isOpen={this.state.isVideoOpen}
          videoId={this.state.videoId}
          onClose={() => this.setState({isVideoOpen: false})}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app
})

export default connect(mapStateToProps)(Tutorials)