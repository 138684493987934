import React from "react";
import { ORDER_STATUS, ORDER_TYPE, URGENT_ORDER_REQUEST_STATUS, USER_ROLES } from "../../../core/constants/roles";
import { formatDate } from "../../../core/utils/date";
import { ChangeStatusOrderButton } from "../components/change-status-order-button";
import { DeleteOrderButton } from "../components/delete-order-button";
import { DetailFixOrderButton } from "../components/detail-fix-order-button";
import { DetailOrderButton } from "../components/detail-order-button";
import { DownloadOrderButton } from "../components/download-order-button";
import { DuplicateOrderButton } from "../components/duplicate-order-button";
import { ChangeDeliveryDateRow } from "./rows/changeDeliveryDate/changeDeliveryDate";
import { debounce } from "../../../core/utils/debounce";

export const AdminModal = ({
  idRol,
  show,
  statusOptions,
  onClose,
  onChangeStatus,
  onDeleteOrder,
  onNewDeliveryDate,
  onRequestUrgency,
  onAcceptUrgency,
  onRejectUrgency,
  data,
}) => {
  const currentStatus = data.STATUS;
  const currentUkey = data.UKEY;
  const currentOrderType = data.ORDER_TYPE;
  const currentIdSeries = data.ID_SERIES;

  const currentDeliveryDate = data.DELIVERY_DATE;

  const [deliveryDateModified, setDeliveryDateModified] = React.useState(
    data.DELIVERY_DATE_MODIFIED === "1"
  );

  const [urgencyRequestComment, setUrgencyRequestComment] = React.useState(null)
  const [urgencyRejectionComment, setUrgencyRejectionComment] = React.useState(null)

  const handleSetNewDeliveryDate = (newDeliveryDate) => {
    setDeliveryDateModified(true);
    onNewDeliveryDate && onNewDeliveryDate(newDeliveryDate);
  };

  const handleOnRequestUrgency = () => {
    onRequestUrgency(urgencyRequestComment)
  }

  const handleOnAcceptUrgency = () => {
    onAcceptUrgency()
  }

  const handleOnRejectUrgency = () => {
    onRejectUrgency(urgencyRejectionComment)
  }

  const DetailOrderButtonElm =
    currentOrderType === ORDER_TYPE.NEW
      ? DetailOrderButton
      : DetailFixOrderButton;

  const patientName = `${data.NOMBRE_PACIENTE} ${data.APELLIDOS_PACIENTE}`;
  return (
    <div
      className={`modal fade ${show ? "in" : ""}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={() => onClose && onClose()}
            >
              ×
            </button>
            <h4 className="modal-title">Opciones del pedido</h4>
          </div>
          <div className="modal-body">
            <ChangeDeliveryDateRow
              ukey={currentUkey}
              minimumDate={formatDate(new Date())}
              currentDeliveryDate={currentDeliveryDate}
              onChange={handleSetNewDeliveryDate}
            />
            <div>
              <div className="fifty">
                {currentStatus === "1" || currentStatus === "5"
                  ? "Editar pedido:"
                  : "Ver pedido:"}
              </div>
              <DetailOrderButtonElm
                role={idRol}
                ukey={currentUkey}
                status={currentStatus}
              ></DetailOrderButtonElm>
            </div>
            <div>
              <div className="fifty">Descargar etiqueta: </div>
              <DownloadOrderButton
                ukey={currentUkey}
                patientName={patientName}
                idSeries={currentIdSeries}
                type="etiqueta"
              />
            </div>
            <div>
              <div className="fifty">Descargar etiqueta de envio: </div>
              <DownloadOrderButton
                ukey={currentUkey}
                patientName={patientName}
                idSeries={currentIdSeries}
                type="etiqueta"
                sendLabel={true}
              />
            </div>
            {currentOrderType === ORDER_TYPE.NEW && (
              <div>
                <div className="fifty">Duplicar pedido:</div>
                <DuplicateOrderButton ukey={currentUkey} />
              </div>
            )}

            {[USER_ROLES.CLIENT, USER_ROLES.ADMIN, USER_ROLES.DESIGNER].includes(idRol) && data.URGENCY_STATUS === null && currentStatus === ORDER_STATUS.DESIGN ? (
              <div>
                <div className="fifty" style={{verticalAlign: "top"}}>Solicitar urgencia:</div>
                <div className="urgency-container">
                  <textarea
                    id="urgency-request-comment"
                    className="input-control"
                    placeholder="Especifique el motivo de la urgencia"
                    onChange={(e) => {debounce(setUrgencyRequestComment(e.target.value))}}
                    name="urgency-request-comment"
                  />
                  <div className="urgency-button-row">
                    <button
                      type="button"
                      className="btn btn-success"
                      disabled={!urgencyRequestComment?.trim().length}
                      onClick={() => handleOnRequestUrgency()}
                    >
                      <i className="entypo-check"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {[USER_ROLES.ADMIN, USER_ROLES.DESIGNER].includes(idRol) && data.URGENCY_STATUS !== null ? (
              <div>
                <div className="fifty" style={{verticalAlign: "top"}}>Urgencia:</div>
                <div className="urgency-container">
                  <div className="urgency-comment">
                    <p className="text-primary">{data.URGENCY_COMMENT}</p>
                  </div>
                  {data.URGENCY_STATUS == URGENT_ORDER_REQUEST_STATUS.ACCEPTED && (
                    <div className="urgency-comment">
                      <p className="text-success">Aprobado</p>
                    </div>
                  )}
                  {data.URGENCY_STATUS == URGENT_ORDER_REQUEST_STATUS.REJECTED && (
                    <div className="urgency-comment">
                      <p className="text-danger">Rechazado</p>
                      <p className="text-primary">{data.URGENCY_REJECTION_COMMENT}</p>
                    </div>
                  )}
                  {data.URGENCY_STATUS == URGENT_ORDER_REQUEST_STATUS.PENDING && (
                    <>
                      <textarea
                        id="urgency-reject-comment"
                        className="input-control"
                        placeholder="En caso de rechazo, especifique el motivo"
                        onChange={(e) => {debounce(setUrgencyRejectionComment(e.target.value))}}
                        name="urgency-reject-comment"
                      />
                      <div className="urgency-button-row">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => handleOnAcceptUrgency()}
                        >
                          <i className="entypo-check"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '10px' }}
                          onClick={() => handleOnRejectUrgency()}
                        >
                          <i className="entypo-cancel"></i>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              ) : null}

            {idRol === "99" || idRol === "4" ? (
              <div>
                <div className="fifty">Cambiar el estado del pedido:</div>
                <div className="selectChangeStatus">
                  <ChangeStatusOrderButton
                    ukey={currentUkey}
                    status={currentStatus}
                    statusOptions={statusOptions}
                    onChangeStatus={() => onChangeStatus && onChangeStatus()}
                  ></ChangeStatusOrderButton>
                </div>
              </div>
            ) : null}
            <div>
              <div className="fifty">Eliminar pedido:</div>
              <DeleteOrderButton
                ukey={currentUkey}
                onDelete={() => onDeleteOrder && onDeleteOrder()}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onClick={
                () => onClose && onClose()
                //   this.setState({ showModal: false, currentUkey: 0 })
              }
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
